// Convert a score to a corresponding letter grade
export const conversion = (sumScore: number): string => {
    if (sumScore < 1.5) {
        return 'Aaa';  // Highest grade
    } else if (sumScore < 4.5) {
        return 'Aa';
    } else if (sumScore < 7.5) {
        return 'A';
    } else if (sumScore < 10.5) {
        return 'Baa';
    } else if (sumScore < 13.5) {
        return 'Ba';
    } else if (sumScore < 16.5) {
        return 'B';
    } else if (sumScore < 19.5) {
        return 'Caa';
    } else {
        return 'Ca';  // Lowest grade
    }
};

// Calculate the average score for a given category
export const calculateAverageScore = (grades: any[], category: string): number | null => {
    const categoryGrades = grades.filter(grade => grade.category.trim() === category);

    if (categoryGrades.length === 0) return null;

    const totalScore = categoryGrades.reduce((sum, grade) => sum + grade.score, 0);
    return totalScore / categoryGrades.length;
};
