import React from "react";
import Logo from "../assets/icons/logo_white.svg";
import MainLogo from "../assets/img/pasha_logo.png";

const FallbackPage = () => {
  return (
    <div className="fallback_page dflx-r justify_content_center" style={{backgroundColor: "white"}}>
      <img src={MainLogo} alt="logo" className="animate_rotation" />
    </div>
  );
};

export default FallbackPage;
