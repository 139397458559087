export const __access_token = "__access_token";
export const __refresh_token = "__refresh_token";
export const __months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const __ratings = {
  "1": "Aaa",
  "2": "Aa1",
  "3": "Aa2",
  "4": "Aa3",
  "5": "A1",
  "6": "A2",
  "7": "A3",
  "8": "Baa1",
  "9": "Baa2",
  "10": "Baa3",
  "11": "Ba1",
  "12": "Ba2",
  "13": "Ba3",
  "14": "B1",
  "15": "B2",
  "16": "B3",
  "17": "Caa1",
  "18": "Caa2",
  "19": "Caa3",
  "20": "Ca",
};
