import { __months } from "../constants/Values";

export const datePrettify = (dt: string | Date) => {
  if (!dt) return "-";
  const date = new Date(dt);
  return `${date.getDate().toString().padStart(2, "0")} ${
    __months[date.getMonth()]
  } 
 ${date.getFullYear()}`;
};
