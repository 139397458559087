import { AxiosResponse } from "axios";
import { ILoginDTO } from "../modules/auth/dtos";
import { ILoginResponse } from "../modules/auth/interfaces";
import { integrateToFormData } from "../utils";
import { httpClient } from "./config";

export const authApi = {
  getMe: async () => httpClient.get("/auth/me/"),

  async login(body: ILoginDTO): Promise<AxiosResponse<ILoginResponse>> {
    const data = integrateToFormData(body);
    const response = await httpClient.post("/auth/token/", data);
    return response;
  },

  refresh: (refresh: string): Promise<AxiosResponse<{ access: string }>> =>
    httpClient.post("/auth/token/refresh/", { refresh }),
  getUser: () => httpClient.get("/auth/users/").then((res) => res.data),
};
