import { lazy, memo } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom';
import MainLayout from '../../layouts/main-layout'
import { getUser, useSelector } from '../../redux';

//CALIBRATION
const CalibrationRouter = lazy(() => import('../calibration/router'))

// COMPANIES
const CompaniesRouter = lazy(() => import('../companies/router'))

// GROUP COMPANIES
const GroupCompaniesRouter = lazy(() => import("../group_companies/router"));
const GroupCompaniesModalRouter = lazy(() => import("../group_companies/modal_router"));

// INDUSTRIES
const IndustriesRouter = lazy(() => import("../industries/router"));
const IndustriesModalRouter = lazy(() => import("../industries/modal_router"));

// RATINGS
const RatingsRouter = lazy(() => import("../ratings/router"));

// GLOSSARY
const GlossaryRouter = lazy(() => import("../glossary/router"));

// USERS
const UsersRouter = lazy(() => import("../users/router"));
const UsersModalRouter = lazy(() => import("../users/modal_router"));

// COUNTRIES
const CountriesRouter = lazy(() => import("../countries/router"))
const CountriesModalRouter = lazy(() => import("../countries/modal_router"))

export const UserBaseNavigation = memo(() => {
    const location = useLocation();
    const state = location.state as { backgroundLocation?: Location };
    const user = useSelector(getUser);

    return <MainLayout>
        <Routes location={state ? state.backgroundLocation : location}>
            <Route path="/" element={<RatingsRouter />} />
            <Route path="/companies/*" element={<CompaniesRouter />} />
            <Route path="/countries/*" element={<CountriesRouter />} />
            <Route path="/glossary/*" element={<GlossaryRouter />} />
            <Route path="/calibration/*" element={<CalibrationRouter />} />
            <Route path="/group-companies/*" element={<GroupCompaniesRouter />} />
            {user.user_data?.role === "system_admin" && <Route path="/users/*" element={<UsersRouter />} />}
            <Route path="/industries/*" element={<IndustriesRouter />} />
            <Route path="/ratings/*" element={<RatingsRouter />} />
        </Routes>
        {state?.backgroundLocation && (
            <Routes>
                <Route path="/group-companies/*" element={<GroupCompaniesModalRouter />} />
                <Route path="/users/*" element={<UsersModalRouter />} />
                <Route path="/industries/*" element={<IndustriesModalRouter />} />
                <Route path="/countries/*" element={<CountriesModalRouter />} />
            </Routes>
        )}
    </MainLayout>
})