import { AxiosResponse } from "axios";
import { CreateUserDTO } from "../modules/users/dtos";
import { IUser } from "../modules/users/interface";
import { httpClient } from "./config";
import { urlMaker } from "../utils";

export const userApi = {
  getUsers: async (filters?: object): Promise<AxiosResponse<any>> =>
    httpClient.get(urlMaker("/auth/users/", filters)),
  
  createUser: (body: CreateUserDTO) => httpClient.post("/auth/users/", body),

  getUserById: (id: number | string): Promise<AxiosResponse<IUser>> =>
    httpClient.get(`/auth/users/${id}/`),

  updateUser: (id: number | string, body: CreateUserDTO) =>
    httpClient.put(`/auth/users/${id}/`, body),

  deleteUser: (id: number | string) => httpClient.delete(`/auth/users/${id}`),
};
