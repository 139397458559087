import axios from "axios";
import Cookies from "js-cookie";
import { __access_token, __refresh_token } from "../constants/Values";
import { authApi } from "./auth_api";

export const httpClient = axios.create({
  baseURL: process.env.REACT_APP_AOC_API_URL,
});

httpClient.interceptors.request.use((config) => {
  const aToken = Cookies.get(__access_token);
  if (aToken) {
    config.headers!.Authorization = `Bearer ${aToken}`;
  }
  return config;
});

httpClient.interceptors.response.use(
  (response) => response,
  async (err) => {
    if (
      err.response.status !== 401 ||
      (err.response.status === 401 && err.config.url.includes("/refresh/"))
    ) {
      return Promise.reject(err);
    } else {
      let refresh = Cookies.get(__refresh_token);
      if (!refresh) return Promise.reject(err);
      try {
        const { data } = await authApi.refresh(refresh);
        Cookies.set(__access_token, data.access);
        return httpClient.request(err.config);
      } catch (e) {
        return Promise.reject(e);
      }
    }
  }
);
