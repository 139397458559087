import { CreateGroupCompanyDTO } from "../modules/group_companies/dtos";
import { urlMaker } from "../utils";
import { httpClient } from "./config";

export const groupCompanyApi = {
  getGroupCompanies: (filters?: { [key: string]: any }) =>
    httpClient.get(urlMaker("/companies/company-group/", filters)),

  createGroupCompany: (data: CreateGroupCompanyDTO) =>
    httpClient.post("/companies/company-group/", data),

  updateGroupCompany: (id: string | number, data: CreateGroupCompanyDTO) =>
    httpClient.put(`/companies/company-group/${id}/`, data),

  getGroupCompany: (id: string | number) =>
    httpClient.get(`/companies/company-group/${id}/`),

  deleteGroupCompany: (id: string | number) =>
    httpClient.delete(`/companies/company-group/${id}`),
};
