import { memo, ReactNode } from "react";
import Sidebar from "../components/sidebar/sidebar";
import styles from "./main-layout.module.scss";

const MainLayout = ({ children }: { children: ReactNode }) => {
  return (
    <div className={`layout_main dflx-r align_items_start ${styles.layout_main}`}>
      <Sidebar />
      <main className={`dflx-c w_10 ${styles.main}`}>
        {children}
      </main>
    </div>
  );
};

export default memo(MainLayout);
