import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ratingApi } from "../../api";

export interface IGovernment {
  loading: "idle" | "loading" | "active";
  data: {
    id: number;
    name: string;
    weight: string;
    division: number;
    category: {
      id: number;
      name: string;
      parent: number;
      weight: string;
      criterions: {
        id: number;
        name: string;
        parent: number;
        weight: string;
        grades: {
          id: number;
          score: number;
          definition: string;
        }[];
      }[];
    }[];
  }[];
}

export const getGovernmentAsync = createAsyncThunk(
  "government/get_government",
  () =>
    ratingApi.getGovernment().then((response) => {
      const data = response.data;
      const result: any[] = [];
      data.forEach((gov: any) => {
        const entity: Record<string, any> = {
          id: gov.id,
          weight: gov.weight,
          division: gov.division,
          name: gov.name,
        };
        const category: any[] = [];
        gov.criterions.forEach((elem: any) => {
          if (!elem.parent) {
            if (!elem.grades.length) {
              category.push({
                id: elem.id,
                name: elem.name,
                criterions: gov.criterions.filter(
                  (cr: any) => cr.parent === elem.id
                ),
              });
            } else {
              category.push({
                id: elem.id,
                name: elem.name,
                weight: elem.weight,
                parent: elem.parent,
                criterions: [
                  {
                    id: elem.id,
                    name: elem.name,
                    parent: elem.parent,
                    weight: elem.weight,
                    grades: elem.grades,
                  },
                ],
              });
            }
          }
        });
        entity["category"] = category;
        result.push(entity);
      });
      return result;
    })
);

const initialState: IGovernment = {
  data: [],
  loading: "idle",
};
const governmentSlice = createSlice({
  name: "user_credentials",
  initialState,
  reducers: {
    clearGovernment: (state) => {
      state.data = [];
      state.loading = "idle";
    },
  },
  extraReducers: {
    [getGovernmentAsync.pending.type]: (state) => {
      state.loading = "loading";
    },
    [getGovernmentAsync.fulfilled.type]: (
      state,
      action: PayloadAction<IGovernment["data"]>
    ) => {
      state.loading = "active";
      state.data = action.payload;
    },
    [getGovernmentAsync.rejected.type]: (state) => {
      state.loading = "idle";
    },
  },
});

export const { clearGovernment } = governmentSlice.actions;

export const { reducer: governmentReducer } = governmentSlice;
