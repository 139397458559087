import { lazy, memo, Suspense, useMemo } from "react";
import FallbackPage from "../../components/fallback_page";
import { getUser, useSelector } from "../../redux";
import { UserBaseNavigation } from "./user";

// AUTH
const LoginPage = lazy(() => import("../auth/pages/Login.page"));

const BaseRouter = () => {
  const user = useSelector(getUser);

  const appContent = useMemo(() => {
    switch (user.loading) {
      case "active":
        return <UserBaseNavigation />
      case "idle":
      case "loading":
        return <FallbackPage />
      default:
        return <LoginPage />
    }
  }, [user.loading])


  return (
    <Suspense fallback={<FallbackPage />}>
      {appContent}
    </Suspense>
  );
};

export default memo(BaseRouter);
