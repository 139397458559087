import { memo, useEffect } from 'react'
import BaseRouter from './modules/base/router'
import { getUserAsync, useDispatch, getGovernmentAsync } from './redux';
const App = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getUserAsync());
        dispatch(getGovernmentAsync())
    }, [dispatch]);

    return <BaseRouter />
}

export default memo(App)