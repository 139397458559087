import { __ratings } from "../constants/Values";

export function numberToRating(num: keyof typeof __ratings) {
  if (Object.keys(__ratings).includes(num.toString())) {
    return __ratings[`${num}`];
  }
}
export const convertNumberToRating = (value: number = 0) => {
  if ((!value && value !== 0) || value === 0) return "-";
  if (value > 20) return numberToRating("20");
  if (value < 0) return value.toString();
  if (value < 1)
    return numberToRating(
      Math.trunc(value * 10).toString() as keyof typeof __ratings
    );
  return numberToRating(
    Math.trunc(value).toString().toString() as keyof typeof __ratings
  );
};
