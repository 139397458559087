import { AxiosResponse } from "axios";
import {  ICountryRate } from "../modules/countries/interface";
import { httpClient } from "./config";

export const countryApi = {
  getCountries: (): Promise<AxiosResponse<any>> =>
    httpClient.get("/companies/country/"),

  createCountry: (data: FormData) =>
    httpClient.post("/companies/country/", data),

  deleteCountry: (id: string | number) =>
    httpClient.delete(`/companies/country/${id}/`),

  getCountry: (id: string | number) =>
    httpClient.get(`/companies/country/${id}/`),

  updateCountry: (id: string | number, data: FormData) =>
    httpClient.put(`/companies/country/${id}/`, data),

  addRate: (data: FormData) =>
    httpClient.post("/companies/country-rate/", data),

  getRate: (id: string | number) =>
    httpClient.get(`/companies/country-rate/${id}`),

  updateRate: (id: string | number, data: FormData) =>
    httpClient.put(`/companies/country-rate/${id}/`, data),

  getCountryRate: (
    id: string | number
  ): Promise<AxiosResponse<ICountryRate[]>> =>
    httpClient.get(`/companies/country_ratings/${id}/`),
};
