import { configureStore } from "@reduxjs/toolkit";
import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from "react-redux";
import { userReducer, governmentReducer } from "./reducers";

export const store = configureStore({
  reducer: {
    user: userReducer,
    government: governmentReducer,
  },
});

type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;
export const useDispatch = () => useReduxDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const getUser = (state: RootState) => state.user;
export const getGovernment = (state: RootState) => state.government;
